import NextImage from 'next/image'
import styled, { css } from 'styled-components'

import { Container } from 'components/blocks/sections/directories/AllEntries/ServiceEntries'
import { Button } from 'components/ui'
import { Text } from 'components/ui/deprecated/Text'
import { TwoColumnsStoryblok } from 'lib/storyblok/types'
import {
  textByLine,
  hasValidLink,
  getStoryblokImageAttributes,
  getAnchorFromCmsLink,
} from 'lib/utils/content'
import { cn } from 'lib/utils/tailwind'

import { dashedLine } from '../../../lib/style'
import { CMSRichText } from './CMSRichText'

type Props = {
  block: TwoColumnsStoryblok
}

export const TwoColumns = ({ block, ...props }: Props): JSX.Element => {
  const {
    text_alignment,
    image_position,
    image_width,
    is_list,
    divider,
    image,
    title,
    description,
    button_label,
    button_link,
  } = block

  const imageSpan = {
    left: { wide: 'span 5', default: 'span 4' },
    right: { wide: '4 / span 5', default: '5 / span 4' },
  }
  const contentSpan = {
    // image on the left, content on the right
    left: { wide: '6 / span 3', default: '5 / span 4' },
    // image on the right, content on the left
    right: { wide: 'span 3', default: 'span 4' },
  }

  return (
    <Wrapper {...props}>
      <Container>
        <Holder
          hasDivider={!!divider}
          className={cn(
            text_alignment === 'center' && 'items-center',
            text_alignment === 'top' && 'flex-start'
          )}
        >
          <Content
            span={contentSpan[image_position || 'left']}
            isWide={image_width === 'wide'}
          >
            {title && (
              <Text
                variant="title/large"
                as="h2"
                className={cn(is_list ? 'mb-8' : 'mb-4')}
              >
                {title}
              </Text>
            )}
            {typeof description === 'string' && (
              <div>
                {textByLine(description, (part) => (
                  <Paragraph variant="eighteen" as="p" hasLine={!!is_list}>
                    {part}
                  </Paragraph>
                ))}
              </div>
            )}
            {typeof description === 'object' && description !== null && (
              <div>
                <CMSRichText richtext={description} />
              </div>
            )}
            {button_label && button_link && hasValidLink(button_link) && (
              <div className="mt-8 max-w-full">
                <Button
                  as="a"
                  href={getAnchorFromCmsLink(button_link).href}
                  variant="outline"
                >
                  {button_label}
                </Button>
              </div>
            )}
          </Content>

          {image && image.filename && (
            <ImageHolder
              span={imageSpan[image_position || 'left']}
              isWide={image_width === 'wide'}
            >
              <NextImage
                quality={100}
                {...getStoryblokImageAttributes(
                  image,
                  '1000x1000/smart/filters:format(webp)'
                )}
                style={{ objectFit: 'cover', borderRadius: '1.5rem' }}
              />
            </ImageHolder>
          )}
        </Holder>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  --spacing: 2rem;
  padding-bottom: var(--spacing);

  &:first-of-type {
    padding-top: var(--spacing);
  }

  ${({ theme }) => theme.media.md} {
    --spacing: 3.75rem;
  }
`

const Holder = styled.div<{ hasDivider: boolean }>`
  ${({ hasDivider }) =>
    hasDivider &&
    css`
      padding-bottom: var(--spacing);
      ${({ theme }) => dashedLine('bottom', theme.colors.foreground.default)};
    `}

  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }
`

type ContentProps = { span: { default: string; wide: string }; isWide: boolean }
const Content = styled.div<ContentProps>`
  grid-row: 1;
  padding-bottom: 2rem;
  grid-column: ${({ span }) => span.default};

  ${({ theme }) => theme.media.md} {
    padding: 3rem;
  }

  ${({ theme }) => theme.media.lg} {
    grid-column: ${({ span, isWide }) => (isWide ? span.wide : span.default)};
  }
`

const Paragraph = styled(Text)<{ hasLine: boolean }>`
  ${({ hasLine }) =>
    hasLine &&
    css`
      &:not(:last-of-type) {
        ${({ theme }) => dashedLine('bottom', theme.colors.foreground.default)};
      }
    `}
`

const ImageHolder = styled.div<ContentProps>`
  height: 100%;
  grid-row: 1;
  grid-column: ${({ span }) => span.default};

  & > * {
    height: 100%;
  }

  ${({ theme }) => theme.media.lg} {
    grid-column: ${({ span, isWide }) => (isWide ? span.wide : span.default)};
  }
`
